<script setup lang="ts">
import Button from '../design-system/button.vue';
import UserSettingsBaseContainer from './user-settings-base-container.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import GenericResponse from '~/components/user-interface/generic-error.vue';
import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';

import ToggleSwitch from 'primevue/toggleswitch';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import { tabsPassthrough } from '~/components/passthroughs/tabs';

import type { severity } from '~/interfaces/dto/general/toast-messages';
import type { IBankingAccountSettingsRequest as IBankingPreferences } from '~/interfaces/dto/player/request';

interface ICommunicationDetails {
  preferredCulture: string;
  platformName: string;
  preferredCommunicationChannel: string;
  allowPushNotificationMarketingMessages: boolean;
  allowPushNotificationInformativeMessages: boolean;
  allowPushNotificationReEngagingMessages: boolean;
  allowEmailMarketingMessages: boolean;
  allowEmailInformativeMessages: boolean;
  allowEmailReEngagingMessages: boolean;
  allowSmsMarketingMessages: boolean;
  allowSmsInformativeMessages: boolean;
  allowSmsReEngagingMessages: boolean;
}

type AccountSettingsOption = 'account' | 'banking';

const { $t, $enabled } = useNuxtApp();
const { $authService } = useServices();
const site = useSiteStore();
const localeStore = useLocaleStore();

// Values for normal drop down field
const langOptions: Ref<string[]> = ref();

const switchClasses = 'flex items-center text-xs pb-0 space-x-2';

const communicationDetails = ref<ICommunicationDetails>({
  preferredCulture: '',
  platformName: 'WebDesktop',
  preferredCommunicationChannel: '',
  allowPushNotificationMarketingMessages: false,
  allowPushNotificationInformativeMessages: false,
  allowPushNotificationReEngagingMessages: false,
  allowEmailMarketingMessages: false,
  allowEmailInformativeMessages: false,
  allowEmailReEngagingMessages: false,
  allowSmsMarketingMessages: false,
  allowSmsInformativeMessages: false,
  allowSmsReEngagingMessages: false,
});

const bankingPreferences = ref<IBankingPreferences>({
  allowWithdrawalReversal: false,
});

// submit validation
const valid = ref<boolean>(false);
const disabled = computed<boolean>(() => {
  return valid.value === false;
});

const activeLoader = ref<boolean>(false);
const responseType = ref<severity>();
const responseCode: globalThis.Ref<string> = ref('');
const displayError: globalThis.Ref<boolean> = ref(false);

const accountTab = ref<AccountSettingsOption>();

async function updateAccountSettings() {
  responseCode.value = '';
  activeLoader.value = true;

  // send GA tracking response to update settings
  await trackingMethods.myAccountSettingsUpdate();

  await $authService
    .updateSubscriptions(communicationDetails.value)
    .then((data) => {
      activeLoader.value = false;
      if (data) {
        responseType.value = 'success';
        responseCode.value = 'account-settings-updated';
      } else {
        responseType.value = 'danger';
        responseCode.value = 'account-settings-error';
      }
    })
    .catch(() => {
      activeLoader.value = false;
      responseType.value = 'danger';
      responseCode.value = '1';
    });
}

async function updateBankingSettings() {
  responseCode.value = '';
  activeLoader.value = true;

  // send GA tracking response to update settings
  await trackingMethods.myAccountSettingsUpdate();

  await $authService
    .upsertBankingAccountSettings(bankingPreferences.value)
    .then((data) => {
      activeLoader.value = false;
      if (data.isSuccessful) {
        responseType.value = 'success';
        responseCode.value = 'banking-settings-updated';
      } else {
        responseType.value = 'danger';
        responseCode.value = 'banking-settings-error';
      }
    })
    .catch(() => {
      activeLoader.value = false;
      responseType.value = 'danger';
      responseCode.value = 'banking-settings-error';
    });
}

function validate(value: string) {
  if (value === undefined) {
    valid.value = false;
    return;
  } else if (value.length < 1) {
    valid.value = false;
    return;
  } else {
    valid.value = true;
  }
}

function createLangSelection() {
  const cultures = !!site.getConfig.cultures
    ? site.getConfig.cultures
    : site.getConfig.Cultures;
  if (!!cultures) {
    localeStore.setLocaleOptions(cultures);
    langOptions.value = localeStore.getLocaleOptions;
  }
}

onBeforeMount(async () => {
  activeLoader.value = true;
  displayError.value = false;
  try {
    const data = await $authService.fetchSubscriptions();
    // remember to map culture to culture translation for the view
    communicationDetails.value = { ...communicationDetails.value, ...data };

    const bankingData = await $authService.getBankingAccountSettings();
    bankingPreferences.value.allowWithdrawalReversal =
      bankingData?.data?.allowWithdrawalReversal || false;

    activeLoader.value = false;
  } catch (e) {
    activeLoader.value = false;
    console.error(e);
  }
});

onMounted(async () => {
  createLangSelection();
  accountTab.value = $enabled('myaccount.enableaccountsettings')
    ? 'account'
    : 'banking';
});

watchEffect(() => {
  validate(communicationDetails.value.preferredCommunicationChannel);
});
</script>

<template>
  <GenericResponse v-if="displayError" class="h-min" state="danger">
    {{ $t('there-was-a-problem') }}
  </GenericResponse>
  <UserSettingsBaseContainer v-else class="mb-3">
    <!--    Channel and language-->
    <LazyUserInterfaceGenericLoader v-if="activeLoader" :standalone="true" />
    <Tabs
      v-else
      :value="accountTab"
      class="bonus-tabs w-full allow-scroll my-2 px-2"
      @update:value="($event) => (accountTab = $event as AccountSettingsOption)"
    >
      <TabList
        :pt="tabsPassthrough.tabList"
        class="mb-4"
        v-if="
          $enabled('myaccount.enablewithdrawalrefund') &&
          $enabled('myaccount.enableaccountsettings')
        "
      >
        <Tab value="account">
          <Button
            element-name="account-settings-tab"
            :type="accountTab === 'account' ? 'tertiary' : 'transparent'"
            rounding="lg"
            class="capitalize"
          >
            {{ $t('account') }}
          </Button>
        </Tab>
        <Tab value="banking">
          <Button
            element-name="banking-settings-tab"
            :type="accountTab === 'banking' ? 'tertiary' : 'transparent'"
            rounding="lg"
            class="capitalize"
          >
            {{ $t('banking') }}
          </Button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel
          v-if="$enabled('myaccount.enableaccountsettings')"
          value="account"
        >
          <section class="user-comm-pref min-h-96 p-4 pt-3">
            <div
              class="settings-pref-container bg-layer-1 flex flex-col gap-4 pb-4"
            >
              <header
                class="font-bold p-4 pt-3 border-b border-base dark:!border-dark-900"
              >
                {{ $t('account-settings') }}
              </header>
              <!-- Marketing messages-->
              <div class="flex flex-col gap-2 px-4">
                <span class="text-base-priority font-bold">
                  {{ $t('marketing-messages') }}
                </span>

                <span class="text-xs">
                  {{ $t('marketing-message-description') }}
                </span>

                <!-- Channel and language-->
                <div class="flex flex-wrap justify-between gap-2">
                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="marketing-email">
                        {{ $t('email') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="communicationDetails.allowEmailMarketingMessages"
                      input-id="email-marketing"
                    />
                  </div>

                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="push-marketing">
                        {{ $t('push') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="
                        communicationDetails.allowPushNotificationMarketingMessages
                      "
                      input-id="push-marketing"
                    />
                  </div>
                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="sms-marketing">
                        {{ $t('sms') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="communicationDetails.allowSmsMarketingMessages"
                      input-id="sms-marketing"
                    />
                  </div>
                </div>
              </div>

              <!-- Informative messages-->
              <div class="flex flex-col gap-2 px-4">
                <span class="font-bold text-base-priority">
                  {{ $t('informative-messages') }}
                </span>
                <span class="text-xs">
                  {{ $t('informative-messages-description') }}
                </span>
                <!-- Channel and language-->
                <div class="flex flex-wrap justify-between gap-2">
                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="email-informative">
                        {{ $t('email') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="
                        communicationDetails.allowEmailInformativeMessages
                      "
                      input-id="email-informative"
                    />
                  </div>

                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="push-informative">
                        {{ $t('push') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="
                        communicationDetails.allowPushNotificationInformativeMessages
                      "
                      input-id="push-informative"
                    />
                  </div>

                  <div :class="switchClasses">
                    <div class="flex-1 line-clamp-2">
                      <label for="sms-informative">
                        {{ $t('sms') }}
                      </label>
                    </div>
                    <ToggleSwitch
                      v-model="communicationDetails.allowSmsInformativeMessages"
                      input-id="sms-informative"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full px-4">
                <Button
                  class="uppercase w-full justify-center text-sm"
                  rounding="md"
                  :disabled="disabled"
                  @click="updateAccountSettings"
                >
                  {{ $t('update') }}
                </Button>
              </div>
            </div>
          </section>
        </TabPanel>
        <TabPanel
          v-if="$enabled('myaccount.enablewithdrawalrefund')"
          value="banking"
        >
          <div class="user-comm-pref">
            <div class="settings-pref-container bg-layer-1">
              <p class="ml-4 py-2 sm:py-3 font-bold">
                {{ $t('jpc-banking-settings') }}
              </p>
              <hr class="w-full border-light-200 dark:border-dark-900" />
              <!-- Banking settings-->
              <div class="mx-4">
                <div class="mt-4"></div>
                <div class="text-xs">
                  <p class="mt-0 mb-3">
                    {{ $t('jpc-banking-settings-description') }}
                  </p>
                </div>
                <!-- Channel and language-->
                <div class="grid grid-cols-1 gap-2">
                  <div :class="switchClasses">
                    <label for="reversible-banking" class="mr-2">
                      {{ $t('reversible-withdrawals') }}
                    </label>
                    <ToggleSwitch
                      v-model="bankingPreferences.allowWithdrawalReversal"
                      input-id="reversible-banking"
                    />
                  </div>
                </div>
              </div>
              <div class="text-sm w-full p-4">
                <Button
                  class="mt-2 uppercase w-full justify-center text-sm"
                  rounding="md"
                  :disabled="disabled"
                  @click="updateBankingSettings"
                >
                  {{ $t('update') }}
                </Button>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>

    <div>
      <GenericResponse v-if="responseCode" :state="responseType">
        {{ $t(`jpc-${responseCode}`) }}
      </GenericResponse>
    </div>
  </UserSettingsBaseContainer>
</template>

<style lang="scss">
.user-comm-pref .p-inputswitch-slider {
  border: none;
}
</style>

<style lang="scss" scoped>
label {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
}

.settings-pref-container {
  @apply sm:mt-4 mx-auto sm:max-w-[348px] sm:rounded-lg;
}
</style>
